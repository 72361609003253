import { tv } from '@/utils/styles';

export interface WebBrowserWrapperProps {
  children: React.ReactNode;
  variant?: 'small' | 'default';
}

const WebBrowserWrapper = ({ children, variant = 'default' }: WebBrowserWrapperProps) => {
  const { base, dot, dots, bar, navbar } = styles({ variant });

  return (
    <div className={base()}>
      <div className={navbar()}>
        <span className={dots()}>
          <span className={dot()} style={{ background: '#EB6D62' }} />
          <span className={dot()} style={{ background: '#F3BF59' }} />
          <span className={dot()} style={{ background: '#63C459' }} />
        </span>
        <span className={bar()}>fluentcargo.com</span>
      </div>
      {children}
    </div>
  );
};

const styles = tv({
  slots: {
    base: 'overflow-hidden rounded-md bg-white md:rounded-xl md:shadow-xl',
    navbar: 'hidden h-12 grid-cols-[1fr_3fr_1fr] items-center border-b border-b-grey-300 px-4 md:grid',
    dots: 'flex gap-2',
    dot: 'block size-3 rounded-full',
    bar: 'block w-full rounded-md bg-grey-200 px-4 text-sm italic leading-[28px] text-black/20',
  },
  variants: {
    variant: {
      small: {
        base: '!rounded-lg border border-[#BDD6DD] bg-white !shadow-soft',
        navbar: 'h-6 border-none bg-[#BDD6DD] px-3',
        bar: 'hidden',
        dots: 'gap-1.5',
        dot: 'size-2',
      },
      default: '',
    },
  },
});

export { WebBrowserWrapper };
